

    .sub-menu {
        list-style-type: none;
        padding: 0 $padder * 4 0 0;
        margin: $padder * 2 0 0 0;
        // display: none;

        @include media-breakpoint-up(lg) {
            margin: -$padder * 2 0 0 0;
            display: block;
        }


        a {
            color: $black;
            padding: $padder * 2 0;
            // min-height: 60px;
            @include rhythm(17,20);
            display: block;
            border-bottom: 2px solid $cyan;
            word-break: break-word;
            // margin-bottom: $padder * 0.5;
        }

        > li {
            position: relative;

            &:last-child {
                a {
                    border-bottom: none;
                }
            }

            a {
                color: $black;
            }

            &.active {

                > a {
                    color: $dark;
                }
            }

            &.active-parent.dropdown {

                > a {
                    color: $dark;
                }
            }
        }

    }