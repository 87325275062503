.modal {

    &__person {
    word-break: break-word;

        &--wrap {
            display: inline-block;
            width: auto;
            // text-align: center;
            margin-bottom: $padder * 3;


            @include media-breakpoint-up(md) {
                text-align: left;
                -ms-flex-align: center;
                align-items: center;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: center;
                justify-content: center;
            }
        }

        &--photo {
            // flex-grow:0;
            // flex-shrink:0;
            // max-width: 200px;
            width: 100%;
            display: inline-block;
            text-align: center;
            margin-bottom: $padder * 2;

            img {
                border-radius: 50%;
            }
        }

        &--content {
            // text-align: left;
            @include rhythm(14,16);

            @include media-breakpoint-up(md) {
                @include rhythm(16,18);
            }

        }

        &--details {
            width: 100%;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            margin-bottom: $padder * 2;

            ul {
                list-style-type: none;
                margin: 0 0 0 $padder;
                padding: 0;
                @include rhythm(14,18);

                @include media-breakpoint-up(md) {
                    @include rhythm(16,20);
                }

                li {
                    margin: 0;
                    padding: 0;

                    &.modal__person--name {
                        margin-bottom: 0;

                        h4 {
                            @include rhythm(20,22);
                            margin-bottom: $padder * 0.5;
                        }
                    }

                    &.modal__person--title {
                        margin-bottom: $padder;
                    }
                    &.modal__person--link {
                        margin-top: $padder;
                    }
                    &.modal__person--linkedin {
                        padding-top: $padder;
                    }
                }

            }
        }

        &--name {
        }



        &--title {
            // @include rhythm(20,20);
        }

    }

    &__video {

        .modal-dialog {
            width: 100%;
            max-width: 1400px;
            margin: 30px auto;
        }

        .modal-body {
            position: relative;
            padding: 0px;
        }

        .close {
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 999;
            font-size: 2rem;
            font-weight: normal;
            color: #fff;
            opacity: 1;
        }

    }

}
