.banner {
    margin-bottom: 0;
    // background-color: $primary;
    padding: 0;
    // border-bottom: 2px solid $primary;
    background-color:rgba(255,255,255,0.9);
    // position: relative;
    z-index:10;
    transition: background-color 200ms linear;
    transform: translate3d(0,0,0);
    border-bottom: 1px solid rgba($cyan, 0.35);

    @include media-breakpoint-up(lg) {
        padding: 0 0 $padder 0;
    }

    &.scrolled {
        background-color:#f5f6f5;

        .banner__logo {
            margin-top: $padder * 0.5;
            margin-bottom: 0;

            img {
                width: auto;
                height: 38px;
            }
        }
    }

    > .container {

        @include media-breakpoint-down(lg) {
            padding-left: 0;
            padding-right: 0;
            max-width: 100%;
        }

            padding-top: 6px;

        @include media-breakpoint-up(lg) {
            padding-top: 10px;
        }

        > .row {

            @include media-breakpoint-down(lg) {
                padding-left: 8px;
                padding-right: 8px;
                max-width: 100%;
            }
        }
    }

    .admin-bar & {
        top: 32px;
    }

    .home & {
        // margin-bottom: $padder;
        padding: 0 0 $padder 0;
        @include media-breakpoint-up(md) {
            // margin-bottom: $padder;
        }
    }

    &__logo {
        margin-top: 0;
        margin-bottom: 0;

        img {
            height: 50px;
            width: auto;
            transition: height 200ms linear;
            transform: translate3d(0,0,0);
            max-width: none;
            @include media-breakpoint-up(lg) {
                height: 70px;
            }
        }
    }

    .navbar {

        font-weight: $font-weight-bold;
        text-transform: uppercase;
        // border-bottom: 4px solid $cyan;
        margin-bottom: 0;
        margin-top: $padder;

        @include rhythm(20,20);

        .active, .active-parent {
            > a {
               color: $black !important;
            }
        }

        &-nav {

            > li {

                &:first-child {
                    > a {
                        @include media-breakpoint-up(lg) {
                            padding-left: 0;
                        }
                    }
                }

                > a {
                    @extend .nav-link;
                    border-right: 1px solid $gray-200;
                    padding: 10px;
                    border-bottom: 1px solid $cyan;

                    @include media-breakpoint-up(lg) {
                        border-bottom: none;
                        padding: .5rem 1rem;
                    }
                }

            }

        }
    }

}

.nav-primary {
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.navbar-toggler {
    background-color: rgba($cyan, 0.8);
  padding: 3px 10px;
  font-size: 20px;
  line-height: 20px;
  border: none;
  border-radius: 0;
  margin-left: $padder * 0.4;
  margin-right: 0;
  border: 2px solid #fff;
  border-color: #fff;
  @include media-breakpoint-up(md) {
    margin-left: $padder;
  }

  &.collapsed {
      .navbar-close-icon {
          display: none;
      }

      .navbar-toggler-icon {
          display: inline-block;
      }
  }

  .navbar-toggler-icon {
      display: none;
  }

  .navbar-close-icon {
      cursor: pointer;
      display: inline-block;
      width: 30px;
      height: 30px;
      vertical-align: middle;
      content: "";
      background: no-repeat 50%;
      background-size: 20px 20px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NC45NDkiIGhlaWdodD0iNTQuOTUiIHZpZXdCb3g9IjAgMCA1NC45NDkgNTQuOTUiPiAgPGcgaWQ9Ikdyb3VwXzE0MCIgZGF0YS1uYW1lPSJHcm91cCAxNDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04NDAxLjQzMyAtMzE2My4xMDEpIj4gICAgPGxpbmUgaWQ9IkxpbmVfMjUiIGRhdGEtbmFtZT0iTGluZSAyNSIgeDI9IjQ5LjQwNyIgeTI9IjQ5LjQwNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODQwNC41IDMxNjUuNTc2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS13aWR0aD0iNyIvPiAgICA8bGluZSBpZD0iTGluZV8yNiIgZGF0YS1uYW1lPSJMaW5lIDI2IiB4MT0iNDkuNDA3IiB5Mj0iNDkuNDA3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4NDAzLjkwNyAzMTY2LjE2OCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjciLz4gIDwvZz48L3N2Zz4=);
  }

}



.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 15rem;
    padding: 0;
    margin: 0 0 0;
    font-size: 1rem;
    color: #005bab;
    text-align: left;
    font-weight: $font-weight-normal;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba($cyan,0.5);
    border-radius: 0;
    text-transform: none;

    @include media-breakpoint-up(lg) {
        margin: .125rem 0 0;
    }

    a {
        color: $cyan;
        padding: $padder $padder * 1.5;
        // min-height: 60px;
        @include rhythm(16,16);
        display: block;
        border-bottom: 1px solid rgba($cyan,0.5);
        cursor: pointer;
        // margin-bottom: $padder * 0.5;

        &:hover {
            background-color: rgba($cyan,0.15);
        }


    }

    > li {
        position: relative;

        &:last-child {
            a {
                border-bottom: none;
            }
        }

        &.active, &.active-parent {
            a {
               color: $black;
            }
        }
    }
}

.topbar {
    @extend .d-print-none;
    // @extend .row;
    display: none;
    position: absolute;
    // top: 5px;
    // position: relative;
    background-color: rgb(255,255,255);
    right: 0;
    z-index: 100;
    height: 34px;
    width: 240px;

    @include media-breakpoint-up(lg) {
        right: 15px;
        width: 100%;
    }

    .admin-bar & {
        // top: 37px;
    }

    .close-search {
        position: absolute;
        right: 10px;
        top: 0px;
        color: #fff;
        width: 25px;
        height: 33px;
        padding-top: 3px;
        cursor: pointer;
        text-align: center;

        &:before, &:after {
          position: absolute;
          left: 15px;
          content: ' ';
          height: 25px;
          width: 2px;
          background-color: $black;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
    }

    .searchwrap {
        // border-bottom: 1px solid $white;
        position: relative;
        padding-right: $padder * 3.5;

        label {
            margin-bottom: 0;
            width: 100%;
            display: block;
            text-align: right;
        }

        .search-field {
            width: 100%;
            font-size: 1rem;
            line-height: 1;
            color: $black;
            background-color: $gray-200;
            background-clip: padding-box;
            border: none;
            border-radius: 3px;
            outline: none;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .search-submit {
            position: absolute;
            right: 35px;
            top: 0;
            width: 33px;
            border: none;
            height: 33px;
            background-image: url(../images/arrow.png);
            background-size: cover;
            background-position: center center;
        }
    }


    &__search {
        text-align: right;
        margin-top: 0;
        display: inline-block;
        height: 34px;
        margin-right: 10px;
        @include media-breakpoint-up(lg) {
            margin-right: 0;
        }

        span {
            font-weight: $font-weight-black;
            display: inline-block;
            padding-right: $padder * 0.5;
            @include rhythm(20,20);
            vertical-align: middle;
            cursor: pointer;
        }

        img {
            cursor: pointer;
            width: 34px;
            height: 34px;
        }

    }
}
