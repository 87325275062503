.pagebg {
    z-index: 0;
}

.pushft {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.wrap {
    position: relative;
    z-index: 1;
    flex: 1;
}

.bgfilter {
    z-index: 2;
}

.main {
    > .container:first-child {
        margin-top: 120px !important;
        .admin-bar & {margin-top: 232px !important;}

        @include media-breakpoint-up(lg) {
            margin-top: 200px !important;
        }
    }
    > .slidemargin:first-child {
        margin-top: 84px !important;
        // .admin-bar & {margin-top: 177px !important;}

        @include media-breakpoint-up(lg) {
            margin-top: 150px !important;
        }
    }
    > .container:last-child {
        margin-bottom: 50px;
    }
}

.slidesidebar {
    margin-bottom: $padder * 5;
}

.blocks {
    margin-bottom: $padder * 5;
}

.breadcrumbs {
    @include rhythm(12,12);
    margin-top: $padder * 0.5;
    color: $gray-800;
    margin-bottom: 0;

    @include media-breakpoint-down(lg) {
        padding-left: 8px;
        padding-right: 8px;
    }
    a {
        color: inherit;
    }
}

body {
    color: $gray-700;
    @include rhythm(14,18);
    @include media-breakpoint-up(md) {
        font-size: $font-size-base;
        line-height: $line-height-base;
    }

}

h1 {
    font-size: $h1-font-size * 0.7;
    margin-bottom: $padder * 2.5;
    @include media-breakpoint-up(md) {
        font-size: $h1-font-size;
    }
}
h2 {
    font-size: $h2-font-size * 0.8;
    margin-bottom: $padder * 2;
    @include media-breakpoint-up(md) {
        font-size: $h2-font-size;
    }
}
h3 {
    font-size: $h3-font-size * 0.8;
    margin-bottom: $padder * 2;
    @include media-breakpoint-up(md) {
        font-size: $h3-font-size;
    }
}
h4 {
    font-size: $h4-font-size * 0.8;
    margin-bottom: $padder * 1.5;
    @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
    }
}
h5 {
    font-size: $h5-font-size * 0.9;
    margin-bottom: $padder;
    @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
    }
}

p {
    + h1 {
        padding-top: $padder * 1.5;
    }
    + h2 {
        padding-top: $padder * 1.5;
    }
    + h3 {
        padding-top: $padder * 1.5;
    }
    + h4 {
        padding-top: $padder * 1.5;
    }
    + h5 {
        padding-top: $padder * 1.5;
    }
    + h6 {
        padding-top: $padder * 1.5;
    }
}

.container, .container-fluid {
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.container-fluid {
    text-align: center;

    .block {
        text-align: left;
        // padding-left: $padder / 2;
        // padding-right: $padder / 2;
    }
}

.fixnav {
    width: 100%;
}

.text-black {
    color: $dark !important;
    border-bottom-color: $dark !important;
}

.text-white {
    border-bottom-color: $white !important;
}

.title {
    &--border {
        padding-bottom: 0;
        margin-bottom: 0;
        color: inherit;

        &:after {
            display: block;
            content: ' ';
            margin-top: $padder * 1.5;
            margin-bottom: $padder * 1.5;
            width: $padder * 6;
            height: 0;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: inherit;

            @include media-breakpoint-up(md) {
                margin-top: $padder * 1.5;
                margin-bottom: $padder * 1.5;
            }

        }
    }
    &--borderright {
        padding-bottom: 0;
        margin-bottom: 0;
        color: inherit;

        &:after {
            display: block;
            content: ' ';
            margin-top: $padder * 1.5;
            margin-bottom: $padder * 1.5;
            width: $padder * 6;
            margin-left: auto;
            height: 0;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: inherit;

            @include media-breakpoint-up(md) {
                margin-top: $padder * 1.5;
                margin-bottom: $padder * 1.5;
            }

        }
    }
    &--bordercenter {
        padding-bottom: 0;
        margin-bottom: 0;
        text-align: center;

        &:after {
            display: block;
            content: ' ';
            margin-top: $padder * 1.5;
            margin-bottom: $padder * 1.5;
            width: $padder * 6;
            height: 0;
            margin-left: auto;
            margin-right: auto;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: inherit;

            @include media-breakpoint-up(md) {
                margin-top: $padder * 1.5;
                margin-bottom: $padder * 1.5;
            }

        }
    }
}

iframe {
    width: 100%;
}

.font {
    &--14 {
        @include rhythm(14,18);
    }
    &--18 {
        @include rhythm(18,24);
    }
    &--20 {
        @include rhythm(20,26);
    }
    &--22 {
        @include rhythm(22,30);
    }
}

.subtitle {
    @include rhythm(18,24);
    margin-bottom: 0;
}

.bgfilter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.pagebg {
  float: none;
  width: 100%;
  position: fixed;
  cursor: default;
  opacity: 1;
  margin: 0;
  padding: 0;
  overflow: visible;
  border: none;
  border-radius: 0;
  bottom: 0;
}

.fixthis {
    position: relative;
    z-index: 100;
    &.affix{
        position: fixed;
        top: 0;

        .admin-bar & {
            top: 32px;
        }
    }
}

.hoffect {

    transition: all 0.5s ease;

    &:hover, &:focus, &:active {
        text-decoration: none;
        -webkit-box-shadow: 0px 4px 17px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 4px 17px 0px rgba(0,0,0,0.5);
        box-shadow: 0px 4px 17px 0px rgba(0,0,0,0.5);
    }
}

.pagination {
    padding: $padder;
    line-height: 1rem;
    font-weight: $font-weight-normal;
    // border: 1px solid $gray-200;
    margin-bottom: $padder * 3;
    text-align: center;
    display: block;
    .page {
        &-numbers {
            display: inline-block;
            padding: 0 5px;
            @include rhythm(20,20);
            vertical-align: middle;
        }
    }
    .dots {}
    .next {

        vertical-align: middle;
        @include background-2x( '../images/link_arrow', 'png', 10px, 15px, left top, no-repeat, !important);
        display: inline-block;
        line-height: 15px;
        font-weight: $font-weight-bold;
        box-shadow: none !important;
        margin-left: 10px;
        width: 10px;
        &:hover {
            text-decoration: none;
        }
    }
    .prev {

        vertical-align: middle;
        margin-right: 10px;
        width: 10px;
        @include background-2x( '../images/link_arrow', 'png', 10px, 15px, left top, no-repeat, !important);
        display: inline-block;
        line-height: 15px;
        font-weight: $font-weight-bold;
        box-shadow: none !important;
        transform: rotate(180deg);
        &:hover {
            text-decoration: none;
        }
    }
    .current {
        font-weight: $font-weight-bold;
        text-decoration: underline;
    }
}
