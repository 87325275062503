.page-template-template-kalenteri {

	.slidewrap, .block + .tapahtumat-container {
		margin-bottom: $padder * 2.5;
        @include media-breakpoint-up(lg) {
            margin-bottom: $padder * 5;
        }
	}
}
.page-template-template-sidebar {

	.container .row .block-container {
		padding-left: 0;
		padding-right: 0;
	}
}
