.footer {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
	margin-bottom: 0;
    width: 100%;

    .admin-bar & {
        margin-top: -32px;
    }

    .block {
        margin: $padder * 3 auto;
        text-align: left;
    }

    &__content {

    	a {
    		color: inherit;
    	}

    	p:last-child {
    		margin-bottom: 0;
    	}
    }

}
