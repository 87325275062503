
.hentry.updated {
    display: block;
}

.loadmorewrap {
	width: 100%;
}	

.archiveitem {
    padding-top: $padder * 2;
    padding-bottom: $padder * 2;
    border-bottom-width: 1px;
    border-bottom-color: $cyan;
    border-bottom-style: solid;

    &:last-child {
        border-bottom-width: 0;
    }

    h2 {
        @include rhythm(18,20);
        font-weight: $font-weight-normal;
        margin-bottom: $padder;

        a {
        	color: $cyan;
            text-decoration: none;
        }
    }
    .readmore {
	    display: block;
	    margin-top: 7px;
	}

    .posted {
        @include rhythm(12,14);
        margin-bottom: 0;
    }

    p {
        @include rhythm(16,18);
        margin-bottom: $padder;

        .collapse & {
            margin-bottom: 1rem;
        }
    }
}

.article-img {
	margin-bottom: $padder * 5;
}