.button {
    background-color: $gray-500;
    border:none;
    border-radius: 0px;
    // margin-top: 10px;
    margin-bottom: 10px;
    padding: $padder $padder * 2;
    color: $white;
    @include rhythm(16,20);
    transition: all 0.3s ease;
    font-weight: $font-weight-medium;
    display: inline-block;
    word-break: break-word;
    // @include letter-spacing(80);
    @include media-breakpoint-up(md) {
        padding: $padder * 1.2 $padder * 2.5;
    }


    &:hover, &:focus, &:active {
        text-decoration: none;
        color: $white;
        -webkit-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.95);
        -moz-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.95);
        box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.95);
    }

    &--listing {
        margin-top: $padder * 1.5;
    }


    &--top {
        position: fixed;
        right: $padder * 1.5;
        bottom: $padder * 2;
        cursor: pointer;
        display: block;
        width: 39px;
        height: 26px;
        padding: 0;
        z-index: 100000;
        background-color: transparent;
        border: none;
        outline:0;
        background: transparent url(../images/uparrow.png) no-repeat center center;
        background-size: cover;

        &:focus { outline:0; }
    }

    // &--play {
    //     background-color:rgba(216,216,216,0.75);
    //     border-radius: 50%;
    //     position: relative;
    //     display: inline-block;
    //     margin-bottom: $padder * 2;
    //     border: none;
    //     cursor: pointer;
    //     width: 90px;
    //     height: 90px;

    //     @include media-breakpoint-up(md) {
    //         margin-bottom: $padder * 4;
    //         width: 118px;
    //         height: 118px;
    //     }

    //     &:hover {
    //         background-color:rgba(216,216,216,0.9);
    //     }

    //     span {

    //         cursor: pointer;
    //         position: absolute;
    //         top: 33px;
    //         left: 35px;
    //         display: block;
    //         width: 0;
    //         height: 0;
    //         border-style: solid;
    //         border-width: 13.5px 0 13.5px 30px;
    //         border-color: transparent transparent transparent #ffffff;

    //         @include media-breakpoint-up(md) {
    //             border-width: 17.5px 0 17.5px 35px;
    //             top: 42px;
    //             left:47px;
    //         }
    //     }
    // }

}
