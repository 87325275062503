body#tinymce {
	margin: 12px !important;

	max-width: 1140px;

    .slide--shadow {
        text-shadow: 0px 0px 15px rgba(0,0,0,0.70);
    }

}
