.container-block-details {

    @include media-breakpoint-down(md) {
        background-position: -111111111111px
    }

    .block__details > .container {

        @include media-breakpoint-down(md) {
            // position: absolute;
            max-width: 100%;
            width:100%;
            // top: 0;
        }

    }
}

.slidewrap {
    line-height: 0;
}

.block {
    display: inline-block;
    width: 100%;
    z-index: 3;
    position: relative;
    // padding-top: $padder * 3;
    // padding-bottom: $padder * 3;
    // // max-width: 1190px;
    // @include media-breakpoint-up(md) {
    //     padding-top: $padder * 6;
    //     padding-bottom: $padder * 6;
    // }

    &-container {
        // margin-top: $padder * 3;
        // margin-bottom: $padder * 3;
        // max-width: 1190px;
        @include media-breakpoint-up(md) {
            // margin-top: $padder * 6;
            // margin-bottom: $padder * 6;
        }
    }

    &__logos {

        &--logo {
            margin-bottom: $padder * 3;

            span {
                display: inline-block;
                padding-right: $padder * 0.75;
                padding-left: $padder * 0.75;

                @include media-breakpoint-up(lg) {
                    padding-right: $padder * 1.5;
                    padding-left: $padder * 1.5;
                }
            }

            img {

            }
        }
    }

    &__slideshow {
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 0;

        .slidewrap & {

            @include media-breakpoint-up(md) {
                // padding-left: $padder * 2;
                // padding-right: $padder * 2;
            }

            @include media-breakpoint-up(lg) {
                // padding-left: $padder * 4;
                // padding-right: $padder * 4;
            }

        }

        &--bg {
            @extend .row;
            @extend .justify-content-center;
            @extend .d-flex;
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            margin-left: 0;
            margin-right: 0;
        }

        &--content {
            @include make-col-ready();
            @include make-col(12);
            position: relative;
            z-index: 4;
            // max-width: 1220px;
            margin: 0 auto;
            text-align: center;
            padding-top: $padder;
            padding-bottom: $padder * 2;
            padding-right: $padder * 2;

            @include media-breakpoint-up(md) {
                padding-top: $padder * 2;
                padding-bottom: $padder * 3.5;
                padding-right: $padder * 3.5;
            }

            @include media-breakpoint-up(lg) {
                padding-top: $padder * 3;
                padding-bottom: $padder * 4.5;
                padding-right: $padder * 4.5;
            }

            h1 {
                font-weight: $font-weight-normal;
                margin-bottom: $padder * 3;
                @include rhythm(34,34);

                @include media-breakpoint-up(sm) {
                    @include rhythm(40,40);
                }

                @include media-breakpoint-up(md) {
                    @include rhythm(45,45);
                }

                @include media-breakpoint-up(lg) {
                    @include rhythm(65,65);
                }

                @include media-breakpoint-up(xl) {
                    @include rhythm(70,70);
                }

                &.slide--maintitle {
                    font-weight: $font-weight-normal;
                    margin-bottom: 0;
                    // @include letter-spacing(40);
                    margin-top: 0 !important;
                    padding-top: 0 !important;
                    color: inherit;
                    @include rhythm(22,28);

                    @include media-breakpoint-up(sm) {
                        @include rhythm(30,38);
                    }

                    @include media-breakpoint-up(md) {
                        @include rhythm(42,52);
                    }

                    strong {
                        font-weight: $font-weight-bold;
                    }

                }
            }

            .slide--maintitle {
                font-weight: $font-weight-normal;
                margin-bottom: 0;
                // @include letter-spacing(40);
                margin-top: 0 !important;
                padding-top: 0 !important;
                color: inherit;
                @include rhythm(22,28);

                @include media-breakpoint-up(sm) {
                    @include rhythm(30,38);
                }

                @include media-breakpoint-up(md) {
                    @include rhythm(42,52);
                }

                strong {
                    font-weight: $font-weight-bold;
                }
            }

            .slide--shadow {
                text-shadow: 0px 0px 15px rgba(0,0,0,0.70);
            }


            p {
                font-weight: $font-weight-normal;
                margin-bottom: $padder * 1.5;
                @include rhythm(18,18);

                @include media-breakpoint-up(md) {
                    margin-bottom: $padder * 3;
                    @include rhythm(20,20);
                }

                &.slide--pretitle {
                    // display: inline-block;
                    @include rhythm(16,16);
                    font-weight: $font-weight-normal;
                    margin: $padder 0;
                    // letter-spacing: 0.3px;
                    color: inherit;

                    @include media-breakpoint-up(md) {
                        margin: $padder * 1.2 0;
                        @include rhythm(18,18);
                    }

                    @include media-breakpoint-up(lg) {
                        margin: $padder * 1.5 0;
                    }

                    // &:after {
                    //     display: inline-block;
                    //     content: '';
                    //     margin-left: $padder * 1.4;
                    //     width: $padder * 3;
                    //     border-bottom-width: 1px;
                    //     border-bottom-style: solid;
                    //     border-color: inherit;
                    //     height: 0px;
                    //     vertical-align: middle;

                    //     @include media-breakpoint-up(md) {
                    //         margin-left: $padder * 2.4;
                    //         width: $padder * 6;
                    //     }
                    // }

                }
            }

        }

        &--head {
            height: 220px;

            @include media-breakpoint-up(sm) {
              height: 324px;
            }

            @include media-breakpoint-up(md) {
                height: 432px;
            }

            @include media-breakpoint-up(lg) {
                height: 558px;
            }

            @include media-breakpoint-up(xl) {
              height: 720px;
            }

            @media (min-width: 1700px) {
              height: 844px;
            }
        }
    }

    &__gallery {

        figure {
            margin-bottom: $grid-gutter-width;
            position: relative;
            text-align: center;

            a {
                display: block;
            }

            span {
                display: inline-block;
                // position: absolute;
                // bottom: 0;
                // left: 0;
                background: rgba(255,255,255,0.8);
                width: 100%;
                text-align: center;
                padding: 10px;
                color: $black;
            }
        }
    }

    &__wysiwyg {
        word-break: break-word;


        h1,h2,h3,h4,h5 {
            color: inherit;
        }

        > .container {
            // padding-left: 0;
            // padding-right: 0;
        }

        .title--border:after {
            display: block;
            content: " ";
            margin-top: 35px;
            width: 60px;
            height: 0;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: inherit;
            margin-bottom: 35px;
        }

    }

    &__people {
        margin-bottom: 0;

        .row--title {
            margin-bottom: 0;
        }

        .title {
            @include rhythm(22,22);
            font-weight: $font-weight-normal;

            &--border {
                padding-bottom: 0;
                margin-bottom: 0;
                color: inherit;

                &:after {
                    display: block;
                    content: ' ';
                    margin-top: $padder * 1.5;
                    margin-bottom: $padder * 1.5;
                    width: $padder * 6;
                    height: 0;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    border-bottom-color: inherit;

                    @include media-breakpoint-up(md) {
                        margin-top: $padder * 1.5;
                        margin-bottom: $padder * 1.5;
                    }

                }
            }
            &--borderright {
                padding-bottom: 0;
                margin-bottom: 0;
                color: inherit;

                &:after {
                    display: block;
                    content: ' ';
                    margin-top: $padder * 1.5;
                    margin-bottom: $padder * 1.5;
                    width: $padder * 6;
                    margin-left: auto;
                    height: 0;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    border-bottom-color: inherit;

                    @include media-breakpoint-up(md) {
                        margin-top: $padder * 1.5;
                        margin-bottom: $padder * 1.5;
                    }

                }
            }
            &--bordercenter {
                padding-bottom: 0;
                margin-bottom: 0;
                text-align: center;

                &:after {
                    display: block;
                    content: ' ';
                    margin-top: $padder * 1.5;
                    margin-bottom: $padder * 1.5;
                    width: $padder * 6;
                    height: 0;
                    margin-left: auto;
                    margin-right: auto;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    border-bottom-color: inherit;

                    @include media-breakpoint-up(md) {
                        margin-top: $padder * 1.5;
                        margin-bottom: $padder * 1.5;
                    }

                }
            }
        }

    }

    &__latest {
        margin-bottom: 0px;

        &--group {
            margin-bottom: $padder * 3;
            text-align: center;
        }

        &--title {
            margin-bottom: $padder;
            text-align: left;
            position: relative;;
            // letter-spacing: 0.6px;
            @include rhythm(30, 36);
        }

        &--subtitle {
            color: $dark;
            text-transform: uppercase;
            margin-bottom: $padder * 4;
            text-align: left;
            position: relative;
            @include rhythm(20, 22);
        }


        .latest {

            &__item {
                margin-bottom: $grid-gutter-width;
                padding: 0;
                text-align: left;

                a {
                    display: block;
                    transition: all 0.5s ease;

                    &:hover, &:focus, &:active {
                        text-decoration: none;
                        -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.5);
                        -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.5);
                        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.5);
                    }
                }

                &--texts {
                    padding: $grid-gutter-width;
                    border: 1px solid $gray-400;
                }

                &--date {
                    color: $gray-600;
                    margin-bottom: $padder * 2;
                    @include rhythm(15,15);
                }

                h4 {
                    margin-bottom: $padder * 2.5;
                    @include rhythm(22,26);
                }

                &--excerpt {
                    color: $gray-700;
                    @include rhythm(17,23);
                    margin-bottom: 0;
                }

            }

            &__first {
                margin-bottom: $grid-gutter-width;
                padding: 0;
                text-align: left;

                a {
                    display: block;
                    // transition: all 0.5s ease;

                    &:hover, &:focus, &:active {
                        text-decoration: none;
                    }
                }

                &--texts {
                    padding: $grid-gutter-width 0;
                    border-bottom: 1px solid $gray-400;
                }

                &--date {
                    color: $gray-600;
                    margin-bottom: $padder * 1.5;
                    @include rhythm(15,15);
                }

                h4 {
                    margin-bottom: $padder * 2;
                    @include rhythm(22,26);
                }

                &--excerpt {
                    color: $gray-700;
                    @include rhythm(17,23);
                    margin-bottom: 0;
                }

            }

            &__small {
                padding: 0;
                text-align: left;
                border-bottom: 1px solid $gray-400;
                padding: 0 0 $grid-gutter-width / 2 0;
                margin-bottom: $grid-gutter-width / 2;

                @include media-breakpoint-up(lg) {
                    margin-bottom: $grid-gutter-width;
                    padding: 0 0 $grid-gutter-width 0;
                }

                a {
                    display: block;
                    // transition: all 0.5s ease;

                    &:hover, &:focus, &:active {
                        text-decoration: none;
                        // -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.5);
                        // -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.5);
                        // box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.5);
                    }
                }

                &--texts {
                }

                &--image {
                    padding-right: 0;
                }

                &--date {
                    color: $gray-600;
                    margin-bottom: $padder;
                    @include rhythm(15,15);
                }

                h4 {
                    margin-bottom: $padder;
                    @include rhythm(17,20);

                    @include media-breakpoint-up(lg) {
                        @include rhythm(20,24);
                    }
                }

                &--excerpt {
                    color: $gray-700;
                    margin-bottom: 0;
                    @include rhythm(15,19);

                    @include media-breakpoint-up(lg) {
                        @include rhythm(17,23);
                    }
                }

            }

        }
    }

    &__textcols {
        // padding-top: $padder * 4;
        // padding-bottom: $padder * 4;
        word-break: break-word;

        @include media-breakpoint-up(md) {
            // padding-top: $padder * 6;
            // padding-bottom: $padder * 6;
        }

        h1,h2,h3,h4,h5,a {
            color: inherit;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .colpadder {
            // padding-top: 0;
            // padding-bottom: $padder * 2;

            &--sides {
                padding-top: $padder * 2;
                padding-bottom: $padder * 2;
                padding-right: $padder * 2;
                padding-left: $padder * 2;
            }
        }

        .textcols {

            &__col {
                margin-bottom: $padder * 3;
                word-break: break-word;

                @include media-breakpoint-up(md) {
                    margin-bottom: $padder * 3;
                }

                &--content {

                    // padding: $padder * 2;

                    p, ul, ol {
                        // margin-bottom: $padder * 1.5;

                        @include media-breakpoint-up(md) {
                            // margin-bottom: $padder * 3;
                        }
                    }
                }

                &--pretext {
                    // padding-bottom: $padder * 3;
                    border-bottom-width: 0;
                    border-bottom-style: solid;
                    border-bottom-color: inherit;
                }
            }
        }

        .newsitem {
            padding: $padder 0;
            border-bottom-width: 1px;
            border-bottom-color: inherit;
            border-bottom-style: solid;

            &:last-child {
                border-bottom-width: 0;
            }

            h5 {
                @include rhythm(16,20);
                font-weight: $font-weight-normal;
                margin-bottom: $padder * 0.5;

                a {
                    text-decoration: none;
                }
            }

            .posted {
                @include rhythm(12,14);
                margin-bottom: 0;
            }

            p {
                @include rhythm(14,16);
                margin-bottom: $padder;

                .collapse & {
                    margin-bottom: 1rem;
                }
            }
        }

    }

    &__files {

        &--file {

            a {
                display: block;
                padding: 10px;
                background-color: $gray-200;
                margin-bottom: 10px;
                transition: background-color 200ms linear;
                transform: translate3d(0,0,0);

                &:hover {
                    text-decoration:none;
                    background-color: darken($gray-300, 20%);
                }

                span {
                    display: inline-block;
                    margin-right: 10px;
                    padding: 5px;
                    background-color: $red;
                    text-transform: uppercase;
                    @include rhythm(12,12);
                    vertical-align: middle;
                    text-align: center;
                    color: $white;
                }
            }

        }

    }

}

.person {
    word-break: break-word;

    &--wrap {
        // display: inline-block;
        // width: auto;
        text-align: center;
        margin-top: $padder;


        // @include media-breakpoint-up(md) {
            // text-align: left;
            // -ms-flex-align: center;
            // align-items: center;
            // display: -ms-flexbox;
            // display: flex;
            // -ms-flex-pack: center;
            // justify-content: center;

            // .article__wrapper & {
            //     justify-content: left;
            // }
        // }
    }

    &--photo {
        // flex-grow:0;
        // flex-shrink:0;
        // max-width: 200px;
        // width: 40%;
        width: 100%;
        margin-bottom: $padder;

        img {
            width: 100%;
        }
    }

    &--details {
        // width: 60%;
        // display: inline-block;
        // vertical-align: middle;
        text-align: center;
        // max-width: 60%;
        // min-width:
        word-break: break-word;
        color: inherit;

        // @include media-breakpoint-up(sm) {
        //     width: 70%;
        // }

        // @include media-breakpoint-up(md) {
        //     max-width: none;
        //     width: 60%
        // }

        // @include media-breakpoint-up(lg) {
        //     width: 50%;
        // }

        a, p, ul, ol, li, h1, h2, h3, h4, h5, span {
            color: inherit;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            @include rhythm(14,16);

            @include media-breakpoint-up(lg) {
                @include rhythm(16,18);
            }

            li {
                margin: 0;
                padding: 0;

                &.person--name {
                    margin-bottom: 0;

                    h4 {
                        font-weight: $font-weight-light;
                        @include rhythm(18,20);
                        margin-bottom: $padder * 0.5;

                        // @include media-breakpoint-up(lg) {
                        //     @include rhythm(18,20);
                        // }
                    }
                }

                &.person--title {
                    @include rhythm(16,18);
                    margin-bottom: $padder * 0.5;
                }

                &.person--phone {
                    @include rhythm(15,18);
                }

                &.person--email {
                    @include rhythm(15,18);
                }
                &.person--link {
                    margin-top: $padder;
                    .button {
                        text-transform: uppercase;
                    }

                }
                &.person--linkedin {
                    padding-top: $padder * 1.5;
                    padding-bottom: $padder * 0.5;
                    text-align: center;

                    a {
                        display: inline-block;
                        padding: $padder * 0.5 $padder;
                        background-color: $white;
                    }
                }
            }

        }
    }

    &--name {
    }



    &--title {
        // @include rhythm(20,20);
    }

}
