

.row {
    &--cc {
        @extend .row;
        @extend .justify-content-center;
        @extend .align-items-center;
    }
    &--ct {
        @extend .row;
        @extend .justify-content-center;
        @extend .align-items-start;
    }
    &--title {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: $padder * 3;

        h2 {
            margin-bottom: 0;

            & + p {
                width: 100%;
                display: inline-block;
                margin-top: $padder * 1.5;
                margin-bottom: 0;
            }
        }

    }
    &--txtimg {
        @extend .row;
        @extend .no-gutters;
        margin-bottom: 0;
    }

}

.justcenter {
    @extend .justify-content-center;
}

.justend {
    @extend .justify-content-end;
}
