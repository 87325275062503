.events {
    margin-bottom: $padder * 3;

    .headlink {
        padding-left: $padder;
        font-weight: $font-weight-bold;

        a {
            font-weight: $font-weight-light;
        }
    }

    &--month {
        font-weight: $font-weight-normal;
        @include rhythm(20,20);
        margin-bottom: 0;
        padding-bottom: $padder * 1.5;
        margin-top: $padder * 2;
        padding-left: $padder;
        border-bottom-width: 1px;
        border-bottom-color: $gray-400;
        border-bottom-style: solid;
    }
}

.event {

    @include rhythm(14,18);
    padding: $padder * 0.6 0;      

    border-bottom-width: 1px;
    border-bottom-color: $gray-400;
    border-bottom-style: solid;

    &:nth-child(odd) {background: $gray-200;}

    &.col-event {

        &:nth-child(odd) {background: transparent;}        

        border-bottom-width: 1px;
        border-bottom-color: inherit;
        border-bottom-style: solid;

        &:last-child {
            border-bottom-width: 0;
        }

        .event__coldate {
            @include make-col-ready();
            @include make-col(3);
            padding-left: 0;

            @include media-breakpoint-up(lg) {
                @include make-col(3);
            }
            @include media-breakpoint-up(xl) {
                @include make-col(2.5);
            }
        }

        .event__coltext {
            @include make-col-ready();
            @include make-col(9);

            @include media-breakpoint-up(lg) {
                @include make-col(9);
            }
            @include media-breakpoint-up(xl) {
                @include make-col(9.5);
            }

        }
        
    }

    p {
        // margin-bottom: $padder * 0.5;
        
        &:last-child {
            margin-bottom: 0;
        }

        &.timedate {
            // @include rhythm(13,16);
            // border: 1px solid #c1c8e2;
            // border-radius: 3px;
            // padding: 5px;
            // background-color: #f4f7ff;
            margin-bottom: 0;
        }
    }

    .panel {
        > p {
            margin-bottom: $padder * 0.5;
        
            &:last-child {
                margin-bottom: 0;
            }

        }

        .collapse {
            &__content {
                padding: $padder 0;
                display: inline-block;
            }
        }
    }

    .moreinfo {
        display: block;
        &:after {
            display: inline-block;
            content: '-';
            margin-left: $padder;
            border: 1px solid #ccc;
            border-radius: 2px;
            width: 20px;
            height: 18px;
            text-align:center;
            font-size:16px;
            line-height: 16px;
            font-weight: $font-weight-black;
        }

        &.collapsed {
            &:after {
                display: inline-block;
                content: '+';
            }
        }

    }

    &--title {
        font-family: $font-family-base;
        font-weight: $font-weight-medium;
        @include rhythm(14,18);
        margin-bottom: 0;        

        span {
            display: inline-block;
            font-weight: $font-weight-light;
        }
    }

    &--place {
        @include rhythm(12,18);
    }

    &__single {
        @include make-col-ready();
        @include make-col(12);
        padding-left: $padder;
        padding-right: 0;
    }

    &__coldate {
        @include make-col-ready();
        @include make-col(3);
        padding-left: $padder;
        padding-right: 0;

        @include media-breakpoint-up(lg) {
            @include make-col(3);
        }
        @include media-breakpoint-up(xl) {
            @include make-col(2);
        }
    }

    &__coltext {
        @include make-col-ready();
        @include make-col(9);
        padding-left: $padder;
        padding-right: $padder;

        @include media-breakpoint-up(lg) {
            @include make-col(9);
        }
        @include media-breakpoint-up(xl) {
            @include make-col(10);
        }

    }
}

.singleevent {

    p {
        // margin-bottom: $padder * 0.5;
        

        &.timedate {
            // @include rhythm(13,16);
            // border: 1px solid #c1c8e2;
            // border-radius: 3px;
            // padding: 5px;
            // background-color: #f4f7ff;
        }
    }

    .panel {
        > p {
            margin-bottom: $padder * 0.5;
        
            &:last-child {
                margin-bottom: 0;
            }

        }

        .collapse {
            &__content {
                padding: $padder 0;
                display: inline-block;
            }
        }
    }

    .moreinfo {
        display: block;
        &:after {
            display: inline-block;
            content: '-';
            margin-left: $padder;
            border: 1px solid #ccc;
            border-radius: 2px;
            width: 20px;
            height: 18px;
            text-align:center;
            font-size:16px;
            line-height: 16px;
            font-weight: $font-weight-black;
        }

        &.collapsed {
            &:after {
                display: inline-block;
                content: '+';
            }
        }

    }

    &--content {
        margin-top: $padder * 3;
        border-top: 1px solid $cyan;
        padding-top: $padder * 3;
    }


    &--place {

    }

    &__single {
        @include make-col-ready();
        @include make-col(12);
        padding-left: $padder;
        padding-right: 0;
    }

}